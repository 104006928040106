<template>
  <div ref="body">
    <custom-header active="exh" :subActive="current"></custom-header>
    <!-- <div class="banner-container">
      <van-swipe ref="banner" :autoplay="5000" class="fill">
        <van-swipe-item v-for="(item, idx) in banners" :key="idx">
          <div class="fill" @click="urlLink(item.url)">
            <img :src="item.bannerPicture" alt="" style="width: 100%; height: auto;">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="content-container">
      <h1 class="text-bold">ARTICLES</h1>
      <div class="list">
        <van-empty v-if="news.length === 0" description="" />
        <template v-else>
          <div class="flex flex-wrap">            
            <div class="news-item" v-for="(item, i) in news" :key="i" @click="toNewsDetail(item)">
              <img :src="item.newsCover" alt=""  class="cover">
              <div>
                <div class="name text-cut2 text-bold">{{ item.newsTitle }}</div>
              </div>
            </div>
          </div>
        </template>
        
        <div class="pagination-box flex justify-center">
          <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getNewsList } from '@/api/index.js'

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen-China International Furniture Exhibition & Creative Week', // 页面标题
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'China Shenzhen Creative Week & the 39th Shenzhen International Furniture Expo Will Be Held at the Shenzhen Exhibition & Convention Center From March 16-19, 2025.'},
    ],
  },
  data() {
    return {
      active: '',
      current: 'news',
      news: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
      pages: 1,
      keyword: '',
    }
  },
  created() {
    this.getNews();
  },
  methods: {
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getNews();
    },
    getNews() {
      let params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        // type: {expo: 1, news: 2, smart: 4}[this.active], // 0-逛展小程序展会资讯, 1-展会资讯, 2-品牌资讯, 3-论坛资讯, 4-智能家居资讯
        typeList: '1,2,4',
        // recommend: null, // 是否推荐, 1-显示在首页, 0-不推荐, 不传参-所有
        random: false,
      };
      
      getNewsList(params).then(res => {
        let {records = [], total = 0, pages = 1 } = res.resultData;
        this.news = records;
        this.total = total;
        this.pages = pages;
      })
    },
    toNewsDetail(row) {
      this.$router.push({
        path: '/articleDetail',
        query: {
          id: row.newsId,
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    h1 {
      text-align: center;
      margin: 20px auto;
      font-size: 16px;
    }
    
    .operate-bar {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
      
      .serch-bar {
        margin: 5px 0 15px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 55px;
          height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 13px;
        }
        
        .serch-box {
          width: 290px;
          height: 30px;
          padding: 0 10px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 4px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 13px;
            padding-left: 5px;
          }
        }
    
        .van-icon-search {
          font-size: 16px;
          font-weight: bold;
        }
      }
    
      .tabs {
        width: 100%;
        justify-content: space-between;
        
        .tab-item {
          // width: 170px;
          line-height: 32px;;
          cursor: pointer;
          text-align: center;
          background-color: #f0f0f0;
          font-weight: normal;
          font-size: 13px;
          border-radius: 4px;
          padding: 0 20px;
          
          &.active {
            background-color: #060211;
            color: #fff;
          }
          
        }
        
      }
    
    }
    
    .list {
      width: 375px;
      padding: 0 5px;
      margin-bottom: 20px;
      
      /deep/ .van-empty__image {
        width: 80%;
        height: auto;
      }
      
      .news-item {
        width: 172px;
        margin: 0 5px 10px;
        padding-bottom: 5px;
        
        .cover {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 4px;
        }
        
        .name {
          width: 100%;
          margin: 10px 0;
          padding: 0 5px;
          font-size: 13px;
          line-height: 1.5em;
        }
        
        .intro {
          font-size: 3.2px;
          padding: 0 2px;
          margin-bottom: 2px;
        }
        
      }
    
    }
    
    .pagination-box {
      margin-top: 20px;
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 14px;
          height: 24px;
          line-height: 24px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 24px;
          border-radius: 4px;
          padding: 0 5px;
          font-size: 14px;
          height: 24px;
          line-height: 24px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 24px;
        }
      }
    
    }
    
  }
}

// PC
@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 10px auto 20px;
    
    h1 {
      text-align: center;
      margin-bottom: 8px;
      font-size: 6px;
    }
    
    .operate-bar {
      display: flex;
      width: 100%;
      
      .serch-bar {
        width: 70px;
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 16px;
          height: 8px;
          border-radius: 1px;
          padding: 0;
          font-size: 3px;
        }
        
        .serch-box {
          width: 54px;
          height: 8px;
          padding: 2px 3px;
          margin-right: 2px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 1px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 3px;
            padding-left: 2px;
          }
        }
    
        .van-icon-search {
          font-size: 4.8px;
          font-weight: bold;
        }
      }
        
      .tabs {
        margin-left: 30px;
        
        .tab-item {
          // width: 30px;
          height: 10px;
          line-height: 10px;
          padding: 0 8px;
          margin: 0 5px;
          cursor: pointer;
          text-align: center;
          background-color: #eee;
          font-weight: normal;
          font-size: 4px;
          border-radius: 3Px;
          
          &.active,
          &:hover {
            background-color: #060211;
            color: #fff;
          }
          
        }
        
      }
    
    }
    
    .list {
      width: 280px;
      
      /deep/ .van-empty__image {
        width: 40%;
        height: auto;
      }
      
      .news-item {
        width: 50px;
        margin: 0 7px 6px 0;
        padding-bottom: 2px;
        border-radius: 1px;
        
        &:nth-child(5n) {
          margin-right: 0;
        }
        
        &:hover {
          box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
          cursor: pointer;
          
          .cover {
            border-radius: 1px 1px 0 0;
          }
        }
        
        .cover {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 1px;
          background-size: auto 100%;
          background-position: center;
        }
        
        .name {
          width: 100%;
          padding: 0 2px;
          margin: 2px 0;
          box-sizing: border-box;
          margin-bottom: 1px;
          font-size: 3px;
        }
        
        .intro {
          font-size: 3.2px;
          padding: 0 2px;
          margin-bottom: 2px;
        }
        
      }
      
      .pagination-box {
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 3.6px;
            font-weight: 700;
            height: 8px;
            line-height: 8px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 8px;
            border-radius: 1px;
            padding: 0 1px;
            font-size: 3.6px;
            height: 8px;
            line-height: 8px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 8px;
          }
        }
      
      }
      
    }
    
  }
}
  
</style>